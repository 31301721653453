import {useHomepageStore} from '../stores/HomepageStore.js';
import {usePageMetadata} from '../composables/useMeta.js';
import {gtm} from '../composables/gtm.js';
import {useServerPage} from "../composables/useServerPage.js";

export default defineNuxtRouteMiddleware(async (to, from) => {
    const pageType = await useServerPage('homepage', '/');
    useHomepageStore().setHomepageData(pageType);
    usePageMetadata().initializePageMetadata(pageType);

    gtm.openPage('homepage');
});
